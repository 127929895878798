import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import hc from '../../images/hcdes.png'
import paradorHot from '../../images/paradorHotdes.png'
import puertamedina from '../../images/puertamedinades.png'

const Events = [
    {
        Simg: paradorHot,
        title: 'Parador de Sigüenza',
        hab1: 'Habitación individual: 170€',
        hab2: 'Habitación doble: 180€',
        hab3: 'Habitación triple: 190€',
        code: 'Código: 4870',
        tel: '+34 949 39 01 00',
        animation: '1200',
    },
    {
        Simg: hc,
        title: 'Hotel HC Sigüenza',
        hab1: 'Habitación doble terraza: 77€',
        hab2: 'Habitación doble alameda: 87€',
        hab3: 'Cama supletoria: +25€',
        code: 'Código: Boda Fátima y Óscar',
        tel: '+34 949 39 19 74',
        animation: '1400',
    },
    {
        Simg: puertamedina,
        title: 'Hotel Puerta Medina',
        hab1: 'Habitación doble: 70€',
        code: 'Código: Boda Fátima y Óscar',
        tel: '+34 949 39 15 65',
        animation: '1600',
    },

]

const EventSectionS3 = (props) => {
    return (
        <section className="wpo-event-section-s3 section-padding" id="alojamiento">
            <div className="container">
                <SectionTitle MainTitle={'Alojamiento'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.hab1}</li>
                                                <li>{event.hab2}</li>
                                                <li>{event.hab3}</li>
                                                <li>{event.code}</li>
                                                <li>{event.tel}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSectionS3;