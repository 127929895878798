import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg2 from '../../images/event/2.jpg'
import sImg3 from '../../images/event/3.jpg'
import LocationMap from './Modal'
import { Spotify } from 'react-spotify-embed';


const Events = [
    {
        Simg: process.env.PUBLIC_URL + '/buenafuente.jpg',
        title: 'La Ceremonia',
        li1: 'Monasterio de la Madre de Dios de Buenafuente del Sistal',
        li2: 'Buenafuente del Sistal, Olmeda de Cobeta, 19443, Guadalajara',
        maps: 'https://maps.app.goo.gl/NMQetq83hvdo8zoM7'
    },
    {
        Simg: process.env.PUBLIC_URL + '/parador.jpg',
        title: 'La Celebración',
        li1: 'Parador Nacional de Turismo del Castillo Medieval de Sigüenza',
        li2: 'Plaza del Castillo, s/n, Sigüenza, 19250, Guadalajara',
        maps: 'https://maps.app.goo.gl/TrATDCdodUL85mUw5'
    },
]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="eventos">
            <div className="container">
                <SectionTitle MainTitle={'Dónde'} />
                <div className="wpo-event-wrap">
                    <div className="row justify-content-center">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><LocationMap url={event.maps}/></li>
                                                {/* <li><a href={event.maps} target="_blank" rel="noopener noreferrer">Como llegar</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;