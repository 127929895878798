import React from "react";
import { Slide } from "react-awesome-reveal";
import shape1 from '../../images/slider/shape1.svg'
import shape2 from '../../images/slider/shape2.svg'
import TimeCountDown from "../countdown";



const Hero2 = () => {
    return (
        <section className="static-hero" id='inicio'>
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div className="shape-1">
                                        <Slide fill="#AB7C94" direction="up" duration="1000" triggerOnce="true">
                                            <img src={shape1} alt="" />
                                        </Slide>
                                    </div>
                                    <div className="slide-title">
                                        <Slide direction="up" duration="1200" triggerOnce="true">
                                            <h2>Fátima & Óscar</h2>
                                        </Slide>
                                    </div>
                                    <div className="slide-text">
                                        <Slide direction="up" duration="1400" triggerOnce="true">
                                            <p>28 de septiembre de 2024 a las 12:00</p>
                                        </Slide>
                                    </div>
                                    <div className="wpo-wedding-date">
                                        <Slide direction="up" duration="1600" triggerOnce="true">
                                            <div className="clock-grids">
                                                <div id="clock">
                                                    <TimeCountDown />
                                                </div>
                                            </div>
                                        </Slide>
                                    </div>
                                    <div className="shape-2">
                                        <Slide direction="up" duration="1800" triggerOnce="true">
                                            <img src={shape2} alt="" />
                                        </Slide>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="static-hero-right">
                <div className="static-hero-img scene" id="scene">
                    <div className="static-hero-img-inner">
                        <img src={process.env.PUBLIC_URL + '/prueba.png'} alt="" />
                    </div>
                    {/* <div className="static-hero-shape-1">
                        <span className="layer" data-depth="0.25">
                            <Slide direction="down" duration="1000" triggerOnce="true">
                                <img src={shape3} alt="" />
                            </Slide>
                        </span>
                    </div>
                    <div className="static-hero-shape-2">
                        <span className="layer" data-depth="0.45">
                            <Slide direction="down" duration="1200" triggerOnce="true">
                                <img src={shape4} alt="" />
                            </Slide>
                        </span>
                    </div>
                    <div className="static-hero-shape-3">
                        <span className="layer" data-depth="0.65">
                            <Slide direction="up" duration="1400" triggerOnce="true">
                                <img src={shape5} alt="" />
                            </Slide>
                        </span>
                    </div>
                    <div className="static-hero-shape-4">
                        <Slide direction="right" duration="1600" triggerOnce="true">
                            <img src={shape6} alt="" />
                        </Slide>
                    </div> */}
                </div>
                <div className="static-hero-img-bg">
                    <img src={process.env.PUBLIC_URL + '/fondo3.png'} alt="" />
                </div>
            </div>
        </section>
    )
}

export default Hero2;