import React, { useState } from 'react'

const RSVPFrom = () => {
    const [forms, setForms] = useState({
        name: '',
        lastname: '',
        attend: 'yes',
        email: '',
        telf: '',
        meal: '',
        intolerances: '',
        additional: ''
    });

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const changeHandler = e => {
        setIsError(false);
        setIsSuccess(false);
        setForms({ ...forms, [e.target.name]: e.target.value })
    };

    const submitHandler = e => {
        setLoading(true);
        e.preventDefault();
        setIsError(false);
        postData();
    };

    async function postData() {
        fetch('https://255gcp64w6.execute-api.eu-west-1.amazonaws.com/prod/rsvp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '651te1CvPf3Z8qiOh44wi1udEeNZwcQq7HRJobLF'
            },
            body: JSON.stringify(forms)
        }).then(function (response) {
            console.log(response)
            if (response.status == 200) {
                setIsSuccess(true);
            } else {
                throw new Error('Something went wrong');
            }
            setLoading(false);
        }).catch(function (error) {
            setIsError(true);
            console.log('There has been a problem with your fetch operation: ', error.message);
            setLoading(false);
        });
    }

    const changeHandlerRadio = e => {
        setForms({ ...forms, attend: e.target.id })
    }

    const resetForm = (e) => {
        e.preventDefault();
        setForms({
            name: '',
            lastname: '',
            attend: 'yes',
            email: '',
            telf: '',
            meal: '',
            intolerances: '',
            additional: ''
        })
        setIsSuccess(false);
        setIsError(false);
    }

    return (
        <form className="contact-validation-active" onkeydown="return event.key != 'Enter';" onSubmit={(e) => submitHandler(e)}>
            {!isSuccess && <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            onkeydown="return event.key != 'Enter';"
                            value={forms.name}
                            type="text"
                            name="name"
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Nombre*"
                            required />
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            onkeydown="return event.key != 'Enter';"
                            value={forms.lastname}
                            type="text"
                            name="lastname"
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Apellido*"
                            required />
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input
                                onkeydown="return event.key != 'Enter';"
                                type="radio"
                                id="yes"
                                name="radio-group"
                                defaultChecked
                                onChange={(e) => changeHandlerRadio(e)} />
                            <label htmlFor="yes">Sí, estaré ahí</label>
                        </p>
                        <p>
                            <input
                                type="radio"
                                onkeydown="return event.key != 'Enter';"
                                id="no"
                                name="radio-group"
                                onChange={(e) => changeHandlerRadio(e)} />
                            <label htmlFor="no">Lo siento, no podré</label>
                        </p>
                    </div>
                </div>
                {forms.attend == 'yes' && <>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <input
                                onkeydown="return event.key != 'Enter';"
                                value={forms.email}
                                type="text"
                                name="email"
                                onChange={(e) => changeHandler(e)}
                                className="form-control"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <input
                                onkeydown="return event.key != 'Enter';"
                                value={forms.telf}
                                type="text"
                                name="telf"
                                onChange={(e) => changeHandler(e)}
                                className="form-control"
                                placeholder="Teléfono"
                            />
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <select
                                onkeydown="return event.key != 'Enter';"
                                onChange={(e) => changeHandler(e)}
                                value={forms.meal}
                                type="text"
                                className="form-control"
                                name="meal"
                                required
                            >
                                <option value="" hidden>Elige tu plato principal*</option>
                                <option>Solomillo de ternera al punto, patatas panaderas y salsa de trufa</option>
                                <option>Trancha de rodaballo, verduras de temporada al vapor y crema de ajada</option>
                                <option>Lasaña de verduras naturales al gratín</option>
                                <option>Alcachofas confitadas con praliné (Vegano)</option>
                            </select>
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <input
                                onkeydown="return event.key != 'Enter';"
                                value={forms.intolerances}
                                type="text"
                                name="intolerances"
                                onChange={(e) => changeHandler(e)}
                                className="form-control"
                                placeholder="Intolerancias y preferencias de comida" />
                        </div>
                    </div>
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <input
                                onkeydown="return event.key != 'Enter';"
                                value={forms.additional}
                                type="text"
                                name="additional"
                                onChange={(e) => changeHandler(e)}
                                className="form-control"
                                placeholder="¿Algo más que nos quieras comentar?" />
                        </div>
                    </div>
                </>}
            </div>}
            <div className="submit-area">
                {!isSuccess && !loading && <button type="submit" className="theme-btn">Enviar</button>}
                {!isSuccess && loading && <button type="submit" disabled className="theme-btn">Enviando...</button>}
                {isSuccess && (
                    <div style={
                        {
                            "backgroundColor": "#dff0d8",
                            color: "#3c763d",
                            border: "1px solid #d6e9c6",
                            padding: "10px",
                            "borderRadius": "5px",
                            "textAlign": "center"
                        }
                    }>
                        ¡Gracias por confirmar tu asistencia! <br />
                    </div>
                )}
                {isSuccess && <div style={{ marginTop: '40px', marginBottom: '10px' }}> Si quieres rellenar otro formulario, haz click aquí</div>}
                {isSuccess && <button onClick={(e) => resetForm(e)} className="theme-btn">Otro formulario</button>}
                {isError && (
                    <div style={
                        {
                            "backgroundColor": "#f2dede",
                            color: "#a94442",
                            border: "1px solid #ebccd1",
                            padding: "10px",
                            marginTop: "50px",
                            "borderRadius": "5px",
                            "textAlign": "center"
                        }
                    }>
                        Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo más tarde.
                    </div>
                )}
            </div>
        </form >
    )
}

export default RSVPFrom;