import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/A.png'
import coupleImg2 from '../../images/B.png'
import SectionTitle from '../SectionTitle'

const CoupleSection4 = (props) => {

    return (
        <section className="wpo-couple-section-s4 section-padding" id="contacto">
            <SectionTitle MainTitle={'Contacto'} />
            <div className="container-fluid">
                <div className="couple-area clearfix">
                    <div className="row align-items-center">
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <h3>Fátima Yepes Cagigal</h3>
                                <div>
                                    fatimayepes@outlook.com
                                </div>
                                <div>
                                    +34 626 26 83 71
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-4 col-12">
                            <div className="middle-couple-pic-wrap">
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg1} alt="" />
                                    </div>
                                </div>
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <h3>Óscar J. Prieto Martínez</h3>
                                <div>
                                    oscarprieto@outlook.com
                                </div>
                                <div>
                                    +34 633 09 41 82
                                </div>               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection4;