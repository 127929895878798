import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero2 from '../../components/hero2/hero2';
import CoupleSection2 from '../../components/CoupleSection2/CoupleSection2';
import PortfolioSection from '../../components/PortfolioSection';
import CoupleSection4 from '../../components/CoupleSection4/CoupleSection4';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';
import RsvpSection from '../../components/RsvpSection/RsvpSection';
import EventSection from '../../components/EventSection/EventSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Contactpage from '../../components/Contactpage/Contactpage';
import EventSectionS3 from '../../components/EventSection3/EventSection3';
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
import MusicSection from '../../components/MusicSection/MusicSection';

const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero2/>
            <EventSection/>
            <PortfolioSectionS2/>
            <EventSectionS3/>
            <MusicSection/>
            <RsvpSection/>
            <CoupleSection4 />
            <Testimonial2 tClass={'wpo-testimonial-section-s3'}/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;