import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'
import thumb1 from '../../images/testimonial/thumb-1.jpg'
import thumb2 from '../../images/testimonial/thumb-2.jpg'
import thumb3 from '../../images/testimonial/thumb-3.jpg'

import shape1 from '../../images/testimonial/svg-1.svg'
import shape2 from '../../images/testimonial/svg-2.svg'
import shape3 from '../../images/testimonial/svg-3.svg'
import shape4 from '../../images/testimonial/svg-4.svg'


const testimonial = [
    {
        id: '01',
        tImg: ts1,
        thumb: thumb1,
        Des: "El mejor regalo que nos puedes hacer el día de nuestra de nuestro boda es estar ahí, en caso de que quieras colaborar",
        Title: 'Marlin & Williamson',
        Date: "23.05.2023",
    }
]

const Testimonial2 = (props) => {

    const settings = {
        dots: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <section className={`wpo-testimonial-section-s2 ${props.tClass}`}>
            <div className="container">
                <div className="wpo-testimonial-wrap">
                    <div className="row">
                        <div className="col col-lg-12">
                            <div className="wpo-testimonial-items">
                                {/* <div className="wpo-section-title">
                                    <h2>Ayudanos con nuestro proyecto de vida</h2>
                                </div> */}
                                <div className="wpo-testimonial-active">
                                    <div className="wpo-testimonial-item">
                                        <div className="wpo-testimonial-text">
                                            <p>El mejor regalo es disfrutar nuestra boda con vosotros. Si además nos queréis ayudar en nuestro nuevo camino podéis hacerlo en la siguiente cuenta. ¡Muchas gracias!</p>
                                            <div className="wpo-testimonial-text-cuenta">
                                                <span>ES77 0182 1959 09 0201544879</span>
                                            </div>
                                            <div className="wpo-testimonial-text-btm">
                                                <span>SWIFT / BIC - BBVAESMM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="corner-shape">
                                    <div className="shape-1"><img src={shape1} alt="" /></div>
                                    <div className="shape-2"><img src={shape2} alt="" /></div>
                                    <div className="shape-3"><img src={shape3} alt="" /></div>
                                    <div className="shape-4"><img src={shape4} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial2;