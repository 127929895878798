

import React, {Fragment} from 'react';


import { Button } from '@mui/material'
const LocationMap = ({ url, button, buttonClass }) => {

    function handleClickOpen() {
        window.open(url, '_blank');
    }

    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                Cómo llegar
            </Button>
        </Fragment>
    );
}
export default LocationMap


