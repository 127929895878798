import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg2 from '../../images/event/2.jpg'
import sImg3 from '../../images/event/3.jpg'
import { Spotify } from 'react-spotify-embed';


const Events = [
    {
        Simg: process.env.PUBLIC_URL + '/buenafuente.jpg',
        title: 'La Ceremonia',
        li1: 'Monasterio de la Madre de Dios de Buenafuente del Sistal',
        li2: 'Buenafuente del Sistal, Olmeda de Cobeta, 19443, Guadalajara',
        maps: 'https://maps.app.goo.gl/NMQetq83hvdo8zoM7'
    },
    {
        Simg: process.env.PUBLIC_URL + '/parador.jpg',
        title: 'La Celebración',
        li1: 'Parador Nacional de Turismo del Castillo Medieval de Sigüenza',
        li2: 'Plaza del Castillo, s/n, Sigüenza, 19250, Guadalajara',
        maps: 'https://maps.app.goo.gl/TrATDCdodUL85mUw5'
    },
]

const MusicSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="musica">
            <div className="container">
                <SectionTitle MainTitle={'Música'} />
                <div className="wpo-event-wrap">
                    <div className="row justify-content-center">
                        <div className="col col-lg-4 col-md-6 col-12">
                            {/* <Spotify link="https://open.spotify.com/playlist/252vHgRMRuoniEh15unjAN?si=20ffb448d0ff4cc4" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" /> */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '352px', justifyContent: 'center' }}>
                                <p style={{ textAlign: 'center' }}>
                                    La Misa va a tener muchos cantos, y el coro.... ¡EL CORO SOMOS TODOS!
                                    Aquí te dejamos un enlace para escuchar los audios y descargarte las partituras por si quieres participar cantando.
                                </p>
                                <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                                    <li>Ensayos:</li>
                                    <li>📅Sábado 22 de junio a las 18:00-21:00</li>
                                    <li>📅Domingo 8 de septiembre a las 17:00-20:00. Calle Luis Mansilla 2, Tres Cantos.</li>
                                </ul>
                                <button type="submit" style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} onClick={() => { window.open('https://drive.google.com/drive/folders/1MJOKJqIbxJ4Lyv0gQ9jUIHWwVoyfB-q2?usp=sharing', '_blank') }} className="theme-btn">Audios</button>
                                <button type="submit" style={{ width: '100%' }} onClick={() => { window.open('https://drive.google.com/drive/folders/1mgMo8RZ9YHB_AkAyreKu4grJqtuz8hLK?usp=sharing', '_blank') }} className="theme-btn">Partituras</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MusicSection;